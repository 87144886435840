import {useState} from 'react';

import {Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {useLocalStorage, darkBlue} from '@renofi/utils';

import {UserGuide} from '../../components';
import estimatesGuide from '../img/estimates-guide.svg';
import {EXAMPLE_ESTIMATE, EXAMPLE_LOAN_OPTIONS} from '../constants';

import EstimateEmailPreviewModal from './EstimateEmailPreviewModal';

const FIN_ESTIMATE_USER_GUIDE_KEY = 'renofi:fin_estimate_user_guide';

const GUIDE_STEPS = [
  'Enter the project address and the project cost',
  'Generate the financing estimate',
  "Send to your client and get notified when they've seen it",
];

const EstimatesUserGuide = () => {
  const [closed, setClosed] = useLocalStorage(
    FIN_ESTIMATE_USER_GUIDE_KEY,
    null,
  );
  const [showExample, setShowExample] = useState(false);

  const handleClose = () => {
    setClosed({closed: true});
    sendEvent('Contractors/Financing-Estimates/User-Guide-Closed');
  };

  return (
    <>
      <UserGuide
        headline="Share a monthly payment and financing options with your clients."
        buttonText="View example estimates"
        onClick={() => setShowExample(true)}
        imgSrc={estimatesGuide}
        stepsHeading={
          <Text color={darkBlue} fontSize={28}>
            How to use this tool.
          </Text>
        }
        steps={GUIDE_STEPS}
        closeable
        onClose={handleClose}
        closed={closed}
      />
      <EstimateEmailPreviewModal
        estimate={EXAMPLE_ESTIMATE}
        productMatch={EXAMPLE_LOAN_OPTIONS[0]}
        show={showExample}
        onClose={() => setShowExample(false)}
      />
    </>
  );
};

export default EstimatesUserGuide;
