export default `
id
assignedAt
overallScore
cancelledAt
completedAt
createdAt
returnedAt
updatedAt
`;
