import {useEffect, useMemo, useState} from 'react';

import {Flex, Text} from 'rebass';
import {without} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {
  useShareFinancingEstimateViaEmail,
  GET_CONTRACTOR_ESTIMATE_BY_ID,
} from '@renofi/api';
import {Button, useNotifications, Alert} from '@renofi/components';
import {green, useScreenSize, isEmail} from '@renofi/utils';

import {useMyAccount} from '../../../api';
import {Layout} from '../../../components';
import CheckIcon from '../CheckIcon';
import EstimateEmailPreviewModal from '../EstimateEmailPreviewModal';

import {
  EmailRecipients,
  ComposeEmail,
  EmailAttachments,
} from './email-partials';
import {getFormattedMessage} from './utils';

const EmailTab = ({estimate, productMatch}) => {
  const addNotification = useNotifications();
  const {isMobile} = useScreenSize();
  const {contractor, user} = useMyAccount();
  const {shareFinancingEstimateViaEmail, loading} =
    useShareFinancingEstimateViaEmail({
      refetchQueries: [
        {
          query: GET_CONTRACTOR_ESTIMATE_BY_ID,
          variables: {contractorEstimateId: estimate.id},
        },
      ],
    });
  const [attributes, setAttributes] = useState({ccEmailsValid: true});
  const [emailSent, setEmailSent] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const invalidFields = useMemo(() => {
    return Object.keys(attributes).reduce(
      (arr, key) => {
        const value = attributes[key];
        return !value && key !== 'cc' ? arr.concat(key) : arr;
      },
      !estimate?.email ? ['email'] : [],
    );
  }, [JSON.stringify({attributes, estimate})]);

  useEffect(() => {
    const {firstName, propertyAddress} = estimate || {};
    setAttributes({
      ...attributes,
      cc: '',
      subject: `Renovation financing estimate for ${propertyAddress}.`,
      introduction: `Hi ${firstName || 'there'}! Here’s a renovation financing estimate from our friends at RenoFi for your project at ${propertyAddress}.`,
      message: getFormattedMessage(contractor, user),
      attachments: [],
    });
  }, [JSON.stringify({estimate, contractor, user})]);

  const onChangeValue = (key) => (value) => {
    if (key === 'cc') {
      const emailArray = value.replace(/\s+/g, '').split(',');
      const ccEmailsValid = emailArray.every(isEmail);
      setAttributes((s) => ({...s, ccEmailsValid}));
    }
    if (key === 'attachments') {
      const currentAttachments = attributes.attachments || [];
      value = [...currentAttachments, ...value];
    }
    setAttributes((s) => ({...s, [key]: value}));
  };

  const onRemoveAttachment = (attachment) => {
    const updatedAttachments = without(
      attributes.attachments || [],
      attachment,
    );
    setAttributes({...attributes, attachments: updatedAttachments});
  };

  const handleSubmit = async () => {
    try {
      await shareFinancingEstimateViaEmail({
        variables: {
          contractorEstimateId: estimate.id,
          financingEstimateEmailInput: {
            subject: attributes.subject,
            introduction: attributes.introduction,
            ccEmails: attributes.cc.replace(/\s+/g, '').split(','),
            message: attributes.message,
            attachments: attributes.attachments,
          },
          loanProductMatches: [
            {
              productName: productMatch.product.name,
              apr: productMatch.product.rates[0],
              loanTermMonths: productMatch.product.terms * 12,
              offeredLoanAmountExcludingRefinance:
                productMatch.maxLoanAmountOffered,
              monthlyPayment: productMatch.minMonthlyPayment,
              drawPeriodMonths:
                productMatch.product.drawPeriod !== null
                  ? productMatch.product.drawPeriod * 12
                  : null,
              repaymentPeriodMonths: productMatch.product.repaymentPeriod * 12,
              maxBorrowingPower: productMatch.product.maxLoanAmount,
              rateType: productMatch.product.rateType,
            },
          ],
        },
      });
      setEmailSent(true);
    } catch (e) {
      sendEvent('Contractors/Sharing-Estimate-Email-Failed', {
        message: e.message,
      });
      addNotification({
        type: 'error',
        message: 'There was a problem sharing the estimate via email',
      });
    }
  };

  return (
    <Layout.Panel
      contentCss={{paddingLeft: 0, paddingRight: 0, paddingBottom: 0}}
      css={{
        marginLeft: isMobile ? 16 : 'auto',
        marginRight: isMobile ? 16 : 'auto',
        marginBottom: 64,
        maxWidth: isMobile ? '100%' : 736,
      }}>
      <EmailRecipients
        estimate={estimate}
        cc={attributes.cc}
        onChange={onChangeValue('cc')}
        error={
          attributes.ccEmailsValid
            ? null
            : 'Please provide valid email addresses separated by commas.'
        }
      />

      <ComposeEmail
        estimate={estimate}
        productMatch={productMatch}
        subject={attributes.subject}
        onChangeSubject={onChangeValue('subject')}
        introduction={attributes.introduction}
        onChangeIntroduction={onChangeValue('introduction')}
        message={attributes.message}
        onChangeMessage={onChangeValue('message')}
      />

      <EmailAttachments
        isSubmitting={loading}
        onAddAttachments={onChangeValue('attachments')}
        onRemoveAttachment={onRemoveAttachment}
      />

      <Flex
        backgroundColor="rgba(217, 231, 240, 0.20)"
        css={{gap: 16}}
        py={3}
        px={[16, 24]}
        justifyContent="flex-end">
        <Button
          disabled={Boolean(invalidFields?.length)}
          secondary
          xSmall
          onClick={() => setShowPreview(true)}>
          {isMobile ? 'Preview' : 'Preview email'}
        </Button>
        {!emailSent && (
          <Button
            disabled={Boolean(invalidFields?.length)}
            loading={loading}
            onClick={handleSubmit}
            xSmall>
            Send email
          </Button>
        )}
        {emailSent && (
          <Alert
            success
            css={{
              width: ['100%', 'auto'],
              fontWeight: 700,
              lineHeight: '22px',
              padding: '4px 17px',
              borderRadius: 50,
              marginTop: 0,
            }}>
            <Text display="inline-block" mr="8px">
              {isMobile ? 'Sent!' : 'Email sent!'}
            </Text>
            <CheckIcon color={green} />
          </Alert>
        )}
      </Flex>

      <EstimateEmailPreviewModal
        estimate={estimate}
        productMatch={productMatch}
        show={showPreview}
        onClose={() => setShowPreview(false)}
      />
    </Layout.Panel>
  );
};
export default EmailTab;
