import React, {useContext, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {omit} from 'lodash';
import {Box, Flex, Text} from 'rebass';

import {useScreenSize, getLocation, blueGray, gray, blue} from '@renofi/utils';
import {
  Alert,
  Button,
  CurrencyField,
  Label,
  Modal,
  PhoneField,
  StatesField,
  TextField,
  Textarea,
  useNotifications,
} from '@renofi/components';
import {useRequestFinancingOnClientBehalf, useValidateEmail} from '@renofi/api';
import {sendEvent} from '@renofi/analytics';

import {EmailField, Layout, ClientModalCheckbox} from '../../components';
import AppContext from '../../context';
import useShowFinancingReferral from '../useShowFinancingReferral';

const EMPTY_DATA = {
  projectDetails: '',
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  propertyState: '',
  renovationCost: '',
};

const DISABLED_STATES = ['HI', 'NV'];

const DATA_KEYS = Object.keys(omit(EMPTY_DATA, ['projectDetails']));

const RequestClientFinancingModal = () => {
  const {contractor} = useContext(AppContext);
  const {loading, requestFinancingOnClientBehalf} =
    useRequestFinancingOnClientBehalf({
      refetchQueries: ['consolidatedEntities'],
    });
  const addNotification = useNotifications();
  const history = useHistory();

  const {setShowFinancingReferral, showFinancingReferral} =
    useShowFinancingReferral();
  const {loading: loadingValidateEmail, validateEmail} = useValidateEmail();
  const [client, setClientData] = useState(EMPTY_DATA);
  const [consentGiven, setConsentGiven] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const isDisabled =
    loading ||
    loadingValidateEmail ||
    invalidEmail ||
    !consentGiven ||
    DATA_KEYS.some((key) => !client[key]);
  const {isMobile} = useScreenSize();

  const onChangeValue = (key) => (value) => {
    if (key === 'email') {
      setInvalidEmail(false);
    }
    setClientData((s) => ({...s, [key]: value}));
  };
  const onCancel = () => {
    sendEvent('Contractors/Financing-Referral-Form-Cancel');
    setShowFinancingReferral(false);
    removeQueryString();
  };

  const onSubmit = async () => {
    const {data: validationData} = await validateEmail({
      variables: {email: client?.email},
    });

    if (!validationData?.validateEmail?.valid) {
      setInvalidEmail(true);
      return;
    }

    sendEvent('Contractors/Financing-Referral-Form-Submit', {
      budget: client?.renovationCost,
      propertyState: client?.propertyState,
    });
    const rsp = await requestFinancingOnClientBehalf({
      variables: {
        client: {
          ...client,
          renovationCost: client?.renovationCost?.toString(),
        },
        contractorId: contractor?.id,
      },
    });
    const success = rsp?.data?.requestFinancingOnClientBehalf?.success;
    const message = success
      ? "Request received; we'll be in touch with the client shortly"
      : 'There was a problem submitting the request. Please try again';
    const type = success ? 'success' : 'error';

    if (success) {
      setShowFinancingReferral(false);
      removeQueryString();
      setClientData(EMPTY_DATA);
      setConsentGiven(false);
    }
    addNotification({message, type});
  };

  const removeQueryString = () => {
    const location = getLocation();
    if (location?.search.includes('modal=true')) {
      history.replace('/financing');
    }
  };

  if (!showFinancingReferral) {
    return null;
  }

  return (
    <Modal
      show
      light
      hideCloseButton
      contentCss={{width: ['100%', '740'], paddingBottom: 0}}>
      <Layout.PanelTitleV2>Connect client with RenoFi</Layout.PanelTitleV2>
      <Layout.PanelInnerWrapper>
        <Box
          padding={['28px 16px', '32px 40px']}
          css={{
            borderTop: `1px solid ${blueGray}`,
          }}>
          <Alert info icon>
            A RenoFi advisor will contact your client to discuss their loan
            options when a referral is received.
          </Alert>
          <Text fontSize={18} mb={24} mt={40}>
            Client details
          </Text>
          <Flex css={{gap: 24}} flexDirection={['column', 'row']}>
            <Box flex={1}>
              <Label small htmlFor="firstName">
                First name
              </Label>
              <TextField
                id="firstName"
                value={client?.firstName}
                onChange={onChangeValue('firstName')}
              />
            </Box>
            <Box flex={1}>
              <Label small htmlFor="lastName">
                Last name
              </Label>
              <TextField
                id="lastName"
                value={client?.lastName}
                onChange={onChangeValue('lastName')}
              />
            </Box>
          </Flex>
          <Flex mt={24} css={{gap: 24}} flexDirection={['column', 'row']}>
            <Box flex={1}>
              <Label small color={gray} htmlFor="email">
                Email
              </Label>
              <EmailField
                invalidEmail={invalidEmail}
                onChange={onChangeValue('email')}
                placeholder=""
                value={client?.email}
              />
            </Box>
            <Box flex={1}>
              <Label small color={gray} htmlFor="phoneNumber">
                Phone number
              </Label>
              <PhoneField
                icon
                onChange={onChangeValue('phoneNumber')}
                stripCountryCode
                value={client?.phoneNumber}
              />
            </Box>
          </Flex>

          <Flex mt={24} css={{gap: 24}} flexDirection={['column', 'row']}>
            <Box flex={1}>
              <Label small color={gray} htmlFor="projectBudget">
                Project budget
              </Label>
              <CurrencyField
                icon
                limit={9}
                min={0}
                noSpecialCharacters
                onChange={onChangeValue('renovationCost')}
                value={Number(client?.renovationCost)}
              />
            </Box>
            <Box flex={1}>
              <Label small color={gray} htmlFor="propertyState">
                Property state
              </Label>
              <StatesField
                disabledStates={DISABLED_STATES}
                disabledSuffix="&nbsp;&nbsp;(RenoFi is not licensed in this state)"
                onChange={onChangeValue('propertyState')}
                value={client?.propertyState}
              />
            </Box>
          </Flex>
          <Box width="100%" mt={32}>
            <Flex justifyContent="space-between">
              <Label
                small
                color={gray}
                css={{width: 'auto'}}
                htmlFor="projectDetails">
                Additional information
              </Label>
              <Label
                small
                css={{color: blue, width: 'auto', fontStyle: 'italic'}}
                htmlFor="projectDetails">
                Optional
              </Label>
            </Flex>
            <Textarea
              style={{minHeight: 'auto', resize: 'vertical'}}
              onChange={onChangeValue('projectDetails')}
              rows={5}
              value={client?.projectDetails}
            />
          </Box>
          <ClientModalCheckbox
            id="consentGiven"
            name="consentGiven"
            checked={consentGiven}
            onChange={() => setConsentGiven(!consentGiven)}
            css={{marginTop: 16}}>
            I have obtained my client’s consent to share their contact info with
            RenoFi.
          </ClientModalCheckbox>
        </Box>
        <Layout.PanelCTAWrapper small>
          <Button secondary xSmall onClick={onCancel}>
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={onSubmit}
            loading={loading || loadingValidateEmail}
            xSmall
            css={{marginLeft: 16}}>
            {isMobile ? 'Submit referral' : ' Submit referral to RenoFi'}
          </Button>
        </Layout.PanelCTAWrapper>
      </Layout.PanelInnerWrapper>
    </Modal>
  );
};

export default RequestClientFinancingModal;
