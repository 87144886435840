import {gql} from '@apollo/client';

export default gql`
  mutation submitContractorProjectDocument(
    $document: DocumentInputObject!
    $projectId: ID!
    $contractorInformationId: ID
  ) {
    submitContractorProjectDocument(
      document: $document
      projectId: $projectId
      contractorInformationId: $contractorInformationId
    ) {
      document {
        checksum
        addedBy {
          id
          email
        }
        id
        objectName
        source
        fileName
      }
    }
  }
`;
