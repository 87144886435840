import {useContext, useState} from 'react';

import {useHistory} from 'react-router-dom';
import {Flex, Box, Text} from 'rebass';

import {sendEvent} from '@renofi/analytics';
import {blue, gray, useLocalStorage, darkBlue} from '@renofi/utils';
import {
  Label,
  TextField,
  Checkbox,
  CheckboxLabel,
  Button,
} from '@renofi/components';

import AppContext from '../context';
import {ACCOUNT_SETUP_PATHS} from '../constants';
import {getContractorCacheKey} from '../utils';
import {Layout} from '../components';

import {
  BUSINESS_TYPES,
  PROJECT_TYPES,
  PROJECTS_TO_BE_DONE_THIS_YEAR,
  AVG_PROJECT_SIZE,
} from './constants';
import SelectInput from './SelectInput';
import DataInputContainer from './DataInputContainer';
import DistanceSlider from './DistanceSlider';

const BusinessInfo = () => {
  const {user} = useContext(AppContext);
  const history = useHistory();
  const [contractor, cacheContractor] = useLocalStorage(
    getContractorCacheKey(user?.id),
    {},
  );

  const [businessInfo, setBusinessInfo] = useState({
    radiusOfOperation: contractor.radiusOfOperation || 0,
    businessCategory: contractor.businessCategory,
    projectTypes: contractor.projectTypes,
    otherProjectTypeDescription: contractor.otherProjectTypeDescription,
    averageProjectsSizeInDollars: contractor.averageProjectsSizeInDollars,
    projectsToBeDoneInThisYear: contractor.projectsToBeDoneInThisYear,
  });

  const disabled = Boolean(
    businessInfo.radiusOfOperation &&
      !businessInfo.licenseAndInsuranceInOperatingArea,
  );

  const onChange = (key, value) => {
    setBusinessInfo({
      ...businessInfo,
      [key]: value,
    });
  };

  const onSubmit = async () => {
    cacheContractor({...contractor, ...businessInfo});

    sendEvent('Contractors/Business-Info-Added');
    history.push(ACCOUNT_SETUP_PATHS.BUSINESS_CONTACT);
  };

  return (
    <Layout.AuthenticatedPage
      fullScreen
      width="100%"
      css={{maxWidth: 678, margin: '0 auto 0 auto'}}>
      <Text
        alignSelf={['center', 'start']}
        textAlign={['center', 'left']}
        fontSize={36}
        mb={24}
        lineHeight="46px">
        Next, add some basic information about the business.
      </Text>
      <Text
        textAlign={['center', 'left']}
        color={blue}
        fontStyle="italic"
        fontSize={16}
        mb={40}
        lineHeight="19px">
        All questions in this step are optional but help RenoFi understand your
        business better.
      </Text>
      <Flex flexDirection="column" css={{gap: 16}}>
        <SelectInput
          label="How would you describe your business?"
          options={BUSINESS_TYPES}
          value={businessInfo.businessCategory}
          onChange={(value) => onChange('businessCategory', value)}
        />
        <SelectInput
          multiselect
          label={
            <Flex
              flexDirection={['column', 'row']}
              alignItems={['start', 'center']}
              css={{gap: 8}}>
              What types of projects do you mostly work on?{' '}
              <Text
                color={blue}
                fontStyle="italic"
                fontSize={14}
                lineHeight="16px">
                Select all that apply.
              </Text>
            </Flex>
          }
          options={PROJECT_TYPES}
          value={businessInfo.projectTypes}
          onChange={(value) => onChange('projectTypes', value)}>
          {(businessInfo.projectTypes || []).includes('other') && (
            <Box mt={3}>
              <Label
                data-testid="other-type-label"
                small
                htmlFor="otherProjectTypeDescription"
                css={{color: darkBlue, fontWeight: 400}}>
                If Other, please specify.
              </Label>
              <TextField
                id="otherProjectTypeDescription"
                name="otherProjectTypeDescription"
                onChange={(value) =>
                  onChange('otherProjectTypeDescription', value)
                }
                value={businessInfo.otherProjectTypeDescription}
              />
            </Box>
          )}
        </SelectInput>
        <SelectInput
          label="How many projects do you think you’ll do this year?"
          options={PROJECTS_TO_BE_DONE_THIS_YEAR}
          value={businessInfo.projectsToBeDoneInThisYear}
          onChange={(value) => onChange('projectsToBeDoneInThisYear', value)}
        />
        <SelectInput
          label="What is your average project size?"
          options={AVG_PROJECT_SIZE}
          value={businessInfo.averageProjectsSizeInDollars}
          onChange={(value) => onChange('averageProjectsSizeInDollars', value)}
        />

        <DataInputContainer label="How far are you willing to travel for a job?">
          <DistanceSlider
            min={0}
            max={1000}
            step={10}
            decreaseStep={10}
            value={businessInfo.radiusOfOperation}
            onChange={(value) => onChange('radiusOfOperation', value)}
          />
          <Label>
            <Flex mt={26} alignItems="center" css={{gap: 8}}>
              <Checkbox
                checked={Boolean(
                  businessInfo.licenseAndInsuranceInOperatingArea,
                )}
                onChange={() =>
                  onChange(
                    'licenseAndInsuranceInOperatingArea',
                    !Boolean(businessInfo.licenseAndInsuranceInOperatingArea),
                  )
                }
              />
              <CheckboxLabel
                maxWidth={['90%', '100%']}
                fontSize={14}
                color={gray}>
                I confirm that I’m licensed and insured to work within the
                distance specified above.
              </CheckboxLabel>
            </Flex>
          </Label>
        </DataInputContainer>
      </Flex>
      <Flex mt={20} css={{gap: 8}}>
        <Button secondary onClick={() => history.goBack()}>
          Back
        </Button>
        <Button disabled={disabled} onClick={onSubmit}>
          Next
        </Button>
      </Flex>
    </Layout.AuthenticatedPage>
  );
};

export default BusinessInfo;
